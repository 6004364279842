<template lang="pug">
v-form(
  :disabled="isDisabled"
  @submit.prevent="onSubmit"
)
  v-text-field(
    v-model="$v.username.$model"
    label="Username"
    :error-messages="errors.username"
  )
  v-text-field(
    v-model="$v.password.$model"
    v-bind="passwordFieldProps"
    label="Password"
    :error-messages="errors.password"
    @click:append-inner="togglePasswordVisibility"
  )
</template>

<script setup lang="ts">
  import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
  import useVuelidate from '@vuelidate/core'
  import { required, helpers } from '@vuelidate/validators'

  const { withMessage } = helpers

  type Form = {
    username: string,
    password: string
  }

  defineProps<{ isDisabled: boolean }>()
  const $emit = defineEmits(['submit'])

  const { getErrorMessages } = useVuelidateUtils()

  const state = reactive<Form>({
    username: '',
    password: ''
  })

  const rules = computed(() => ({
    username: { required: withMessage('Username is required.', required) },
    password: { required: withMessage('Password is required.', required) }
  }))

  const $v = useVuelidate(rules, state)
  const errors = computed(() => getErrorMessages<Form>($v.value))

  const isPasswordShown = ref(false)
  const passwordFieldProps = computed(() => ({
    type: isPasswordShown.value ? 'text' : 'password',
    appendInnerIcon: isPasswordShown.value ? mdiEyeOutline : mdiEyeOffOutline
  }))

  const togglePasswordVisibility = () => {
    isPasswordShown.value = !isPasswordShown.value
  }

  const onSubmit = () => {
    $emit('submit')
  }

  defineExpose({
    $v,
    payload: computed(() => state)
  })
</script>
